import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { ArrowLeft, HeartStraight } from "@phosphor-icons/react";
import Gem from "components/gem";
import LifeSkillProgress from "components/lifeskill-progress";
import { UserAvatar } from "components/user-avatar";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useNavigate } from "react-router";
import { useAppStore } from "store";
import { clamp } from "utils/helpers";
import { BiRefresh } from "react-icons/bi";
import { Progress } from "flowbite-react";
import { globalStyles } from "styles";
import { getLifeSkillContent, LIFESKILL_CONTENT } from "utils/lifeskills";
import { IoIosSwitch } from "react-icons/io";
import Toggle from "components/ui/toggle";

export function UserStats() {
  const navigate = useNavigate();
  const { user, pointsHistory } = useAppStore();
  const { showModal } = useModal();

  return (
    <div className="flex flex-col bg-white bg-opacity-80 backdrop-blur-lg p-2 rounded-xl shadow-lg ">
      <div className="flex flex-row bg-white rounded-xl items-center justify-between p-2">
        <div className="flex flex-col items-center">
          <span className="text-xs">Conocimento</span>
          <div className="flex items-center space-x-2 text-sm">
            <Gem size={18} type="knowledge" />{" "}
            <b>{pointsHistory?.knowledge || 0}</b>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-xs">Habilidades</span>
          <div className="flex items-center space-x-2 text-sm">
            <Gem size={18} type="skill" /> <b>{pointsHistory?.skill || 0}</b>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-xs">Motivación</span>
          <div className="flex items-center space-x-2 text-sm">
            <Gem size={18} type="motivation" />{" "}
            <b>{pointsHistory?.motivation || 0}</b>
          </div>
        </div>
      </div>

      <div>
        <div className="text-center text-sm">
          <b>Habilidades de vida</b>
        </div>
        <div className="flex flex-col gap-1">
          {LIFESKILL_CONTENT.map((skill) => (
            <div
              key={skill.id}
              onClick={() => showModal("lifeskill")}
              className={`shadow-lg rounded-xl bg-gray-50 px-2 py-1 border ${
                user?.extra.lifeskill === skill.id
                  ? "border-brand-purple"
                  : "border-white"
              } flex flex-row items-center space-x-2`}
            >
              <Toggle
                className="pointer-none"
                checked={user?.extra.lifeskill === skill.id}
              />
              <img
                src={getLifeSkillContent(skill.id)?.image}
                alt={getLifeSkillContent(skill.id)?.name}
                className="h-8 min-w-auto"
              />
              <div className="leading-none text-xs flex-1">
                {getLifeSkillContent(skill.id)?.name}
              </div>
              <span className="text-xs text-right leading-none">
                <b>{user?.point_categories[`lifeskill_${skill.id}`] || 0}</b>
                <br />
                puntos
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
