export interface User {
  _id: string;
  name: string;
  email?: string;
  permissions?: string[];
  roles?: string[];
  image?: any;
  teams?: string[];
  point_categories?: any;
  level?: any;
  challenges?: any;
  challenge_progress?: any[];
  extra?: any;
  catalog_items?: any;
  [key: string]: any;
}

export interface AvatarCharacterPreset {
  skin: number;
  face: number;
  expression: number;
  hair: number;
  upper: number;
  lower: number;
  shoes: number;
  hand_props: number;
  body_props: number;
  accessories: number;
  [key: string]: number;
}

export const DefaultAvatarPreset: AvatarCharacterPreset = {
  skin: 0,
  face: 0,
  expression: 0,
  hair: 0,
  upper: 0,
  lower: 0,
  shoes: 0,
  hand_props: 0,
  body_props: 0,
  accessories: 0,
};