import { api } from './api'

export type Friend = {
	_id: string
	name: string
	image: any
	extra: any
	point_categories: any[]
}
export async function getFriends(player: string | undefined) {
	if (!player) return

	const payload = [{ $match: { 'extra.idInvited': player } }]

	const { data } = await api.post(`/database/player/aggregate`, payload)

	return data
}

async function getFriendsStatus(friends: any) {
	const payload = [
		{
			$match: {
				_id: {
					$in: friends,
				},
			},
		},
		{
			$project: {
				_id: 1,
				name: 1,
				image: 1,
				extra: 1,
				created: 1,
				point_categories: 1,
			},
		},
	]
	const { data } = await api.post('/database/player_status/aggregate', payload)
	return data
}

export async function inviteFriend(player: string, friend: string) {
	const payload = {
		friends: [
			{
				email: friend,
			},
		],

		player: player,
	}

	const { data } = await api.post('/database/invite_friend__c', payload)
	return data
}

export async function fetchInvite(code: string) {
	const payload = {
		code
	}
	const { data } = await api.post('/find/invite', payload)
	if (data.length === 0) return null
	return data[0]
}

export async function getListOfFriends(player: string | undefined) {
	if (!player) return

	const payload = {
		player
	}

	const { data } = await api.post(`/find/friends`, payload)

	return data
}