import { useState } from "react";

type ToggleProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
};

export default function Toggle(props: ToggleProps) {
  return (
    <label
      className={`inline-flex items-center cursor-pointer ${props.className}`}
    >
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={props.checked}
        onChange={props.onChange}
        readOnly
      />
      <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 dark:peer-checked:bg-blue-600"></div>
      {props.label ? (
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {props.label}
        </span>
      ) : null}
    </label>
  );
}
