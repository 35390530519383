import { UserInfo } from "components/user-info";
import gadget from "assets/img/gadget.svg";
import AvatarCharacter from "components/avatar-character";
import { DefaultAvatarPreset } from "types/user";

export default function Notifications() {
  return (
    <>
      <div
        id="home"
        className={`absolute top-0 left-0 w-full h-full flex flex-col items-center md:justify-center overflow-y-auto overflow-x-hidden md:overflow-hidden scroll-wrapper`}
      >
        <UserInfo />

        <div className="absolute bottom-0 md:relative w-[270px] mt-[100px] md:mt-auto ml-[-150px]">
          <AvatarCharacter responsive />
        </div>

        <div className="absolute right-4 md:right-10 bottom-64 md:bottom-20 w-[36vw] md:w-[15vw] animate-fly-fast delay-200">
          <div className="absolute right-0 bottom-full mb-5 bg-white w-[90vw] md:w-96 rounded-xl shadow p-5 space-y-2">
            <p>
              Aquí podrás recibir y enviar mensajes a tus amigos y también
              recibir avisos importantes sobre nuestras actividades, premios y
              otras cosas divertidas.
            </p>
          </div>
          <div className="opacity-0 animate-fade-in delay-100 rotate-12 relative">
            <img src={gadget} alt="" className="-scale-x-100" />
          </div>
        </div>
      </div>
    </>
  );
}
