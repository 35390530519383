import {
  AxiosDefaults,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import { api } from "./api";
import { useAppStore } from "store";
import { clearStorage, getStorageItem, setStorageItem } from "utils/storage";

interface IFailedRequestQueue {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}

let isRefreshing = false;
let failedRequestQueue: IFailedRequestQueue[] = [];

export function setAuthorizationHeader(
  request: AxiosDefaults | AxiosRequestConfig | any,
  token: string
) {
  request.headers.Authorization = `Bearer ${token.replace("Bearer ", "")}`;
}

function handleRefreshToken(refreshToken: string) {
  isRefreshing = true;

  api
    .post("/refresh", { refreshToken })
    .then((response) => {
      const { token } = response.data;

      // createTokenCookies(token, response.data.refreshToken);
      setStorageItem("token", token);
      setAuthorizationHeader(api.defaults, token);

      failedRequestQueue.forEach((request) => request.onSuccess(token));
      failedRequestQueue = [];
    })
    .catch((error) => {
      failedRequestQueue.forEach((request) => request.onFailure(error));
      failedRequestQueue = [];

      clearStorage();
    })
    .finally(() => {
      isRefreshing = false;
    });
}

function onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  const token = getStorageItem("token");

  if (!config.headers) {
    config.headers = {};
  }

  config.headers["content-type"] = "application/json";
  config.headers["cache-control"] = "no-cache";

  if (
    !!config.url?.match(
      /(database\/registration__c)|(player\/password)|(player_register__c)|(player_validate__c)|(find\/invite)/g
    )
  ) {
    config.headers.Authorization =
      "Basic " +
      window.btoa(
        `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_PUBLIC_SECRETKEY}`
      );
  } else {
    if (!!token) {
      setAuthorizationHeader(config, token);
    } else if (!!config.url?.match(/\/global/g)) {
      config.headers.Authorization =
        "Basic " +
        window.btoa(
          `${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_PUBLIC_SECRETKEY}`
        );
    }
  }

  return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function onResponseError(
  error: AxiosError
): Promise<AxiosError | AxiosResponse> {
  if (error?.response?.status === 401) {
    const errorResponse: any = error.response;
    if (errorResponse?.data?.code === "token.expired") {
      const originalConfig = error.config;
      const refreshToken = getStorageItem("refreshToken");

      !isRefreshing && handleRefreshToken(refreshToken);

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: (token: string) => {
            setAuthorizationHeader(originalConfig, token);
            resolve(api(originalConfig));
          },
          onFailure: (error: AxiosError) => {
            reject(error);
          },
        });
      });
    } else {
      document.dispatchEvent(new CustomEvent("signout"));
    }
  }

  return Promise.reject(error);
}

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
