/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { Home } from "pages/Home";
import { Login } from "pages/Auth/Login";
import { NotFound } from "pages/NotFound";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import Onboarding from "pages/Onboarding";
import EditAvatar from "pages/EditAvatar";
import Ending from "pages/Ending";
import Loading from "components/ui/loading";
import Friends from "pages/Friends";
import Ranking from "pages/Ranking";
import Notifications from "pages/Notifications";
import Share from "pages/Share";

// Lazy load pages/Region
const Region = React.lazy(() => import("pages/Region"));
const Profile = React.lazy(() => import("pages/Profile"));
const Achievements = React.lazy(() => import("pages/Achievements"));
const Badges = React.lazy(() => import("pages/Badges"));
const RewardsStore = React.lazy(() => import("pages/Store"));

export const RouteList = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PrivateRoute redirectTo="/login">
          <Home />
        </PrivateRoute>
      }
    />

    <Route
      path="/onboarding/:step"
      element={
        <PrivateRoute redirectTo="/login">
          <Onboarding />
        </PrivateRoute>
      }
    />

    <Route
      path="/ending/:step"
      element={
        <PrivateRoute redirectTo="/login">
          <Ending />
        </PrivateRoute>
      }
    />

    <Route
      path="/region/:id"
      element={
        <PrivateRoute redirectTo="/login">
          <Suspense fallback={<Loading />}>
            <Region />
          </Suspense>
        </PrivateRoute>
      }
    />

    <Route
      path="/profile"
      element={
        <PrivateRoute redirectTo="/login">
          <Suspense fallback={<Loading />}>
            <Profile />
          </Suspense>
        </PrivateRoute>
      }
    />

    <Route
      path="/badges"
      element={
        <PrivateRoute redirectTo="/login">
          <Suspense fallback={<Loading />}>
            <Badges />
          </Suspense>
        </PrivateRoute>
      }
    />

    <Route
      path="/edit-avatar"
      element={
        <PrivateRoute redirectTo="/login">
          <EditAvatar />
        </PrivateRoute>
      }
    />

    <Route
      path="/rewards-store"
      element={
        <PrivateRoute redirectTo="/login">
          <Suspense fallback={<Loading />}>
            <RewardsStore />
          </Suspense>
        </PrivateRoute>
      }
    />

    {/* <Route
      path="/challenges"
      element={
        <PrivateRoute redirectTo="/login">
          <Challenges />
        </PrivateRoute>
      }
    /> */}

    <Route
      path="/achievements"
      element={
        <PrivateRoute redirectTo="/login">
          <Achievements />
        </PrivateRoute>
      }
    />

    <Route
      path="/friends"
      element={
        <PrivateRoute redirectTo="/login">
          <Suspense fallback={<Loading />}>
            <Friends />
          </Suspense>
        </PrivateRoute>
      }
    />

    <Route
      path="/ranking"
      element={
        <PrivateRoute redirectTo="/login">
          <Suspense fallback={<Loading />}>
            <Ranking />
          </Suspense>
        </PrivateRoute>
      }
    />

    <Route
      path="/notifications"
      element={
        <PrivateRoute redirectTo="/login">
          <Suspense fallback={<Loading />}>
            <Notifications />
          </Suspense>
        </PrivateRoute>
      }
    />

    {/* <Route path="/faq" element={<Faq />} /> */}

    {/* <Route path="/terms" element={<Terms />} /> */}

    <Route
      path="/share"
      element={
        <PrivateRoute redirectTo="/login">
          <Share />
        </PrivateRoute>
      }
    />

    <Route
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />

    {/* <Route
      path="/register"
      element={
        <PublicRoute>
          <Register />
        </PublicRoute>
      }
    /> */}

    {/* <Route
      path="/forgot-password"
      element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      }
    /> */}

    <Route path="*" element={<NotFound />} />
  </Routes>
);
