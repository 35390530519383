import { Bell, Users, Gift, CheckCircle } from "@phosphor-icons/react";
import { IoIosPodium } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

export default function Sidebar() {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const navigate = useNavigate();

  if (isMobile) return null;

  return (
    <div className="relative z-20 px-5 flex flex-col justify-center gap-2">
      <div
        onClick={() => navigate("/notifications")}
        className="flex flex-col items-center bg-white rounded-xl shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange"
      >
        <div className="z-10 w-12 flex items-center justify-center text-brand-red">
          <Bell size={30} />
        </div>
        <small>Mesajes</small>
      </div>

      <div
        onClick={() => navigate("/friends")}
        className="flex flex-col items-center bg-white rounded-xl shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange"
      >
        <div className="z-10 w-12 flex items-center justify-center text-brand-blue">
          <Users size={30} />
        </div>
        <small>Amigos</small>
      </div>

      <div
        onClick={() => navigate("/ranking")}
        className="flex flex-col items-center bg-white rounded-xl shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange"
      >
        <div className="z-10 w-12 flex items-center justify-center text-brand-dark-green">
          <IoIosPodium size={30} />
        </div>
        <small>Ranking</small>
      </div>

      <div
        onClick={() => navigate("/rewards-store")}
        className="flex flex-col items-center bg-white rounded-xl shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange"
      >
        <div className="z-10 w-12 flex items-center justify-center text-brand-purple">
          <Gift size={30} />
        </div>
        <small>Premios</small>
      </div>

      <div
        onClick={() => navigate("/badges")}
        className="flex flex-col items-center bg-white rounded-xl shadow p-2 cursor-pointer hover:ring hover:ring-brand-orange"
      >
        <div className="z-10 w-12 flex items-center justify-center text-brand-orange">
          <CheckCircle size={30} />
        </div>
        <small>Logros</small>
      </div>
    </div>
  );
}
