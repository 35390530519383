import Dialog from "components/dialog";
import FullscreenButton from "components/fullscreen-button";
import { useDialog } from "context/DialogContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useMount } from "react-use";
import { useAppStore } from "store";
import { UIElementProps } from "types/prop-types";
import VolumeControl from "./volume-control";
import { actionLog } from "services/actionlog.service";

export function AppContainer({ children }: UIElementProps) {
  const { user, refreshUser, restoreSession, loadAllChallenges } =
    useAppStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { resetDialog } = useDialog();
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [lastPathname, setLastPathname] = useState<string>();

  useEffect(() => {
    if (user && lastPathname !== location.pathname) {
      actionLog("ftm_page_view", {
        event: "page_view",
        value: location.pathname,
      });
      setLastPathname(location.pathname);
    }
  }, [user, location.pathname, lastPathname]);

  // useEffect(() => {
  //   resetDialog();
  // }, [resetDialog]);

  const loadUser = useCallback(async () => {
    restoreSession();
    await refreshUser();
  }, [refreshUser, restoreSession]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <>
      <VolumeControl />
      {/* <FullscreenButton /> */}
      <div className="z-10 flex flex-col absolute w-full h-full overflow-hidden">
        <>{children}</>
        <Dialog />
      </div>
    </>
  );
}
