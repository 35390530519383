import { UserInfo } from "components/user-info";
import gadget from "assets/img/gadget.svg";
import AvatarCharacter from "components/avatar-character";
import { DefaultAvatarPreset } from "types/user";
import { useAppStore } from "store";
import { useCallback, useEffect, useState } from "react";
import { getRanking } from "services/leaderboard.service";
import { set } from "date-fns";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router";

export default function Ranking() {
  const { user } = useAppStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [ranking, setRanking] = useState<any[]>([]);

  const loadData = useCallback(async () => {
    if (!user) return;
    try {
      const results = await getRanking(user._id);
      setRanking(results);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function renderNoRanking() {
    return (
      <div className="absolute right-4 md:right-10 bottom-64 md:bottom-20 w-[36vw] md:w-[15vw] animate-fly-fast delay-200">
        <div className="absolute right-0 bottom-full mb-5 bg-white w-[90vw] md:w-96 rounded-xl shadow p-5 space-y-2">
          <p>
            Invita a tus amigos a Crecer para Ser y podrán competir y divertirse
            para ver quién consigue más puntos cada mes.
          </p>
          <Button
            onClick={() => {
              navigate("/friends");
            }}
          >
            Invitar amigos
          </Button>
        </div>
        <div className="opacity-0 animate-fade-in delay-100 rotate-12 relative">
          <img src={gadget} alt="" className="-scale-x-100" />
        </div>
      </div>
    );
  }

  function renderRanking() {
    return (
      <div className="w-full md:w-1/2 h-full flex flex-col justify-end">
        <div className="bg-white h-full rounded-lg p-2 max-h-[70vh] flex flex-col items-center space-y-4 overflow-y-auto">
          {ranking.map((item: any, index: number) => (
            <div
              key={item._id}
              className={`w-full flex items-center justify-between px-4 py-2 rounded-lg shadow ${
                item.name === user?.extra.nombreUsuario
                  ? "bg-brand-dark-green text-white"
                  : "bg-white"
              }`}
            >
              <div className="flex items-center justify-between w-full gap-5">
                <div className="font-bold pl-3">
                  <p>{index + 1}º</p>
                </div>
                <div>
                  {item.image ? (
                    <img
                      src={item.image.small.url}
                      alt=""
                      className="w-12 h-12 rounded-full"
                    />
                  ) : (
                    <div className="w-12 h-12 bg-gray-100 flex items-center justify-center rounded-full">
                      {item.name[0].toUpperCase()}
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <p>{item.name}</p>
                </div>
                <div
                  className={`text-lg font-bold
                ${
                  item.name === user?.extra.nombreUsuario
                    ? "text-white"
                    : "text-brand-red"
                }
                  `}
                >
                  <p>
                    {item.points || 0} <small>pts</small>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        id="home"
        className={`absolute top-0 left-0 w-full h-full flex flex-col items-center md:justify-center overflow-y-auto overflow-x-hidden md:overflow-hidden scroll-wrapper`}
      >
        <UserInfo />

        {!loading && ranking.length === 0 ? (
          <div className="hidden md:flex absolute left-0 bottom-0 w-[270px] ml-[10px]">
            <AvatarCharacter responsive />
          </div>
        ) : null}

        {loading && (
          <div className="rounded-lg font-bold py-3 px-4 bg-white flex items-center justify-center">
            Cargando...
          </div>
        )}
        {!loading
          ? ranking.length === 0
            ? renderNoRanking()
            : renderRanking()
          : null}
      </div>
    </>
  );
}
