import { api } from "./api";

export async function getLeaderboard(
	leaderboardId: string,
	dates: string,
	viewType: number,
	userId: string
): Promise<any[]> {
	const { data } = await api.get(
		`leaderboard/${leaderboardId}/leaders?${dates}&time_scale=5&view=${viewType}&max_results=9&live=true&player=${userId}`
	);
	return data.leaders;
}

export async function getCompetitionLeaders(
	competitionId: string,
	range = 100
): Promise<any[]> {
	const { data } = await api.get(
		`competition/leader?id=${competitionId}&range=items=0-${range}`
	);
	return data;
}

export async function getRanking(
	userId: string
): Promise<any[]> {

	const payload = {
		player: userId
	}
	const { data } = await api.post(
		`find/ranking`,
		payload
	);
	return data;
}