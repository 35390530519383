import { UserInfo } from "components/user-info";
import gadget from "assets/img/gadget.svg";
import AvatarCharacter from "components/avatar-character";
import { DefaultAvatarPreset } from "types/user";
import { useCallback, useEffect, useState } from "react";
import { useAppStore } from "store";
import { Button } from "components/ui/buttons";
import { UserPlus } from "@phosphor-icons/react";
import { getListOfFriends } from "services/friends.service";
import { BiSend } from "react-icons/bi";

export default function Friends() {
  const { user } = useAppStore();
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleInvite = useCallback(() => {
    const inviteCode = user?.extra.inviteCode;

    const link = `https://teensmart-staging.funifier.app/share?inviteCode=${inviteCode}`;

    // create a message for sharing via whatsapp
    const message = `¡Hey, únete a CRECER Para SER conmigo! 🚀\n\nEs un juego 🎮 para crecer como persona donde podemos hacer misiones juntos. 💪 Regístrate o inicia sesión en JovenSalud usando este enlace para que nuestros usuarios queden conectados automáticamente: ${link}.\n\nMi usuario es: *${user?.extra.nombreUsuario}*. 🌟`;

    // encode the message properly
    const encodedMessage = encodeURIComponent(message);

    // if desktop open the web.whatsapp.com
    // if mobile open the whatsapp app

    let url = `whatsapp://send?text=${encodedMessage}`;

    if (window.innerWidth > 480) {
      url = `https://web.whatsapp.com/send?text=${encodedMessage}`;
    }

    // open the url
    window.open(url, "_blank");
  }, [user]);

  const loadFriends = useCallback(async () => {
    try {
      if (!user) return;
      const result = await getListOfFriends(user?._id);
      setFriends(result || []);
      setLoading(false);
    } catch (error) {
      //
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadFriends();
  }, [loadFriends]);

  function renderNoFriends() {
    return (
      <>
        <div className="absolute bottom-0 md:relative w-[270px] mt-[100px] md:mt-auto ml-[-150px]">
          <AvatarCharacter responsive />
        </div>

        <div className="absolute right-4 md:right-10 bottom-64 md:bottom-20 w-[36vw] md:w-[15vw] animate-fly-fast delay-200">
          <div className="absolute right-0 bottom-full mb-5 bg-white w-[90vw] md:w-96 rounded-xl shadow p-5 space-y-2 text-center">
            <p>Aún no has invitado a ningún amigo.</p>
            <div className="w-full flex justify-center">
              <Button
                className="bg-brand-red text-white py-2 px-4 rounded-lg"
                onClick={handleInvite}
              >
                <div className="flex items-center justify-between">
                  <span>Invitar a un amigo</span>
                  <UserPlus className="ml-2 h-6 w-6" />
                </div>
              </Button>
            </div>
          </div>
          <div className="opacity-0 animate-fade-in delay-100 rotate-12 relative">
            <img src={gadget} alt="" className="-scale-x-100" />
          </div>
        </div>
      </>
    );
  }

  function renderFriends() {
    return (
      <div className="w-full md:w-1/2 h-full flex flex-col justify-end">
        <div className="bg-white h-full rounded-lg p-2 max-h-[70vh] flex flex-col items-center space-y-4 overflow-y-auto">
          <div className="sticky top-0 w-full bg-white rounded flex items-center gap-2 justify-between">
            <h3 className="font-bold text-brand-red p-2">
              Lista de amigos ({friends.length})
            </h3>
            <Button
              className="bg-brand-red text-white py-2 px-1 rounded-lg"
              onClick={handleInvite}
            >
              <div className="flex items-center justify-between">
                <UserPlus className="h-6 w-6" />
              </div>
            </Button>
          </div>
          {friends.map((friend: any) => (
            <div
              key={friend._id}
              className="w-full flex items-center justify-between px-4 py-2 bg-white rounded-lg shadow"
            >
              <div className="flex items-center space-x-4">
                <div>
                  {friend.image ? (
                    <img
                      src={friend.image.small.url}
                      alt=""
                      className="w-12 h-12 rounded-full"
                    />
                  ) : null}
                </div>
                <div>
                  <p>{friend.name}</p>
                </div>
              </div>
              <button className="text-brand-red">
                <BiSend className="h-6 w-6" />
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        id="home"
        className={`absolute top-0 left-0 w-full h-full flex flex-col items-center md:justify-center overflow-y-auto overflow-x-hidden md:overflow-hidden scroll-wrapper`}
      >
        <UserInfo />

        {loading && (
          <div className="rounded-lg font-bold py-3 px-4 bg-white flex items-center justify-center">
            Cargando...
          </div>
        )}
        {!loading
          ? friends.length === 0
            ? renderNoFriends()
            : renderFriends()
          : null}
      </div>
    </>
  );
}
